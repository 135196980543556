import React, { useEffect, useState } from 'react'
import Amplify from '@aws-amplify/core'
import { Router, useLocation } from '@reach/router'
import Authenticator from '../components/auth/Authenticator'
import { useStoreActions } from 'easy-peasy'
import { onAuthUIStateChange, AuthState } from '@aws-amplify/ui-components'
// configs
import awsExports from "../aws-exports";
import { ROUTES } from '../config/routes'
// routes
import Jobs from '../containers/NewJob'
import NewJob from '../containers/NewJob'

Amplify.configure(awsExports)

const Main = ({ isLoggedIn, isDataLoaded }): JSX.Element => {
  return (
    <Authenticator>
      {isLoggedIn && isDataLoaded && 
        <Router basepath={ROUTES.APP_ROOT.path}>
          <Jobs path={ROUTES.NEW_JOB.path} />
          <NewJob path={ROUTES.NEW_JOB.path} />
        </Router>
      }
    </Authenticator>
  )  
}

export default () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [isDataLoaded, setIsDataLoaded] = useState(false)
  const fetchCurrentUser = useStoreActions(actions => () => {})

  useEffect(() => {
    const fetchUser = async () => {
      await fetchCurrentUser()
    }

    fetchUser()

    return onAuthUIStateChange(authState => {
      if (AuthState.SignedIn === authState) {
        setIsLoggedIn(true)
      }
    })
  }, [])

  useEffect(() => {
    setIsDataLoaded(true)
  }, [isLoggedIn])

  return (
    <Main 
      isLoggedIn={isLoggedIn}
      isDataLoaded={isDataLoaded}
    />
  )
}
