import { navigate } from 'gatsby-plugin-intl'

export const ROUTES = {
  ROOT: { path: '/' },
  APP_ROOT: { path: '/app' },
  HOME: { path: '/dashboard' },
  SIGN_IN: { path: '/signin' },
  SIGN_UP: { path: '/signup' },
  JOBS: { path: '/jobs' },
  NEW_JOB: { path: '/jobs/new' },
}

export const redirect = (route, params) => {
  navigate('')
}

export default { 
  ROUTES
}