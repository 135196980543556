import React from 'react'
import { AmplifyAuthenticator, AmplifySignUp } from '@aws-amplify/ui-react'
import Amplify from 'aws-amplify'
import awsConfig from '../../aws-exports'

Amplify.configure(awsConfig)

export default ({ children }): JSX.Element => {
  return (
    <AmplifyAuthenticator
      usernameAlias="email"
      >
      <AmplifySignUp 
        slot="sign-up"
        usernameAlias="email"
      />
      { children }
    </AmplifyAuthenticator>
  )
}